<template>
  <v-card :loading="loading" :disabled="loading" class="mt-n10 moholand-shadow">
    <v-card-title>
      {{ "ویرایش داستان" }}
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-form-base
          :model="model"
          :schema="schema"
          :col="{ cols: 12, md: 6 }"
        />
        <v-row align="center" justify="end" class="mt-8">
          <v-btn @click="clear" depressed>
            پاک کردن فرم
          </v-btn>

          <v-btn
            class="mx-4"
            dark
            color="success"
            @click="submit"
            :loading="loading"
          >
            ویرایش
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { formPostPrepare, formGetPrepare } from "@/constants/PanelFormHandler";
import moment from "jalali-moment";

export default {
  created() {
    this.getData();
    this.getGenres();
    this.getSellers();
  },

  data() {
    return {
      name: this.$route.params.name || "",
      id: this.$route.params.id,
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("edit") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      loading: false,
      rowAttribute: {
        justify: "center",
        align: "center",
        noGutters: false,
      },
      model: {
        title: "",
        user_id: "",
        excerpt: "",
        full_text: "",
        price: "",
        offer_price: "",
        end_date_offer: "",
        status: "",
        status_desc: "",
        genre_id: "",
      },
      schema: {
        title: {
          type: "CustomInput",
          label: "عنوان داستان",
          rules: [Constants.rules.required],
          required: true,
        },
        user_id: {
          type: "CustomInput",
          label: "برای کاربر",
          inputType: "autocomplete",
          items: [],
          rules: [Constants.rules.required],
          itemValue: "id",
          itemText: "value",
          loading: true,
        },
        excerpt: {
          type: "CustomInput",
          label: "توضیحات مختصر",
          required: true,
          col: { cols: 12 },
        },
        full_text: {
          type: "MyTinyMCE",
          label: "متن داستان (نمایش بعد از خرید کاربر)",
          rules: [Constants.rules.required],
          required: true,
          col: { cols: 12 },
        },
        status: {
          type: "CustomInput",
          label: "وضعیت",
          inputType: "select",
          items: [
            { id: 0, value: "در حال بررسی" },
            { id: 1, value: "تایید شده" },
            { id: 2, value: "رد شده" },
          ],
          itemValue: "id",
          itemText: "value",
          rules: [Constants.rules.required],
          required: true,
        },
        status_desc: {
          type: "CustomInput",
          inputType: "textarea",
          solo: true,
          flat: true,
          label: "توضیحات وضعیت (رد/تایید)",
          backgroundColor: "grey lighten-2",
          class: "required rounded-lg",
        },
        price: {
          type: "text-price-field",
          label: "قیمت",
          rules: [Constants.rules.required_price, Constants.rules.numeric],
          persistentHint: true,
          hint: "برای فایل رایگان این مقدار را 0 بگذارید.",
          required: true,
          suffix: "تومان",
        },
        offer_price: {
          type: "text-price-field",
          label: "قیمت با تخفیف",
          rules: [Constants.rules.numeric],
          required: true,
          suffix: "تومان",
        },
        end_date_offer: {
          type: "ModalDatePicker",
          label: "تاریخ پایان",
          min: moment(new Date()).format("YYYY/MM/DD"),
          required: true,
        },
        genre_id: {
          type: "CustomInput",
          label: "ژانر",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "name",
          rules: [Constants.rules.required],
          loading: true,
        },
      },
    };
  },
  components: {
    VFormBase,
  },

  computed: {},

  methods: {
    getData() {
      this.loading = true;
      MyAxios.get("/" + this.$route.meta.api + "/index/" + this.id, {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          formGetPrepare(response, this.model);
          this.model.full_text = response.data.length
            ? response.data[0].full_text_body || ""
            : "";
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getGenres() {
      if (!this.schema.genre_id.loading) this.schema.genre_id.loading = true;
      MyAxios.get("/genres/index", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          this.schema.genre_id = {
            ...this.schema.genre_id,
            loading: false,
            items: response.data,
          };
        })
        .catch((error) => {
          this.schema.categories.loading = false;
        });
    },
    getSellers() {
      MyAxios.get("/user/list/vendor", {
        params: {
          noPaginate: true,
          with: "seller",
        },
      })
        .then((response) => {
          console.log(response);
          this.schema.user_id = {
            ...this.schema.user_id,
            loading: false,
            items: [
              {
                id: 1,
                value: "ادمین",
              },
              ...response.data.map((val) => ({
                id: val.id,
                value:
                  val.first_name +
                  " " +
                  val.last_name +
                  (val.seller && val.seller?.store_name
                    ? " (" + val.seller?.store_name + ")"
                    : ""),
              })),
            ],
          };
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        let price = this.model.price.split(",").join("");
        let offer_price =
          this.model.offer_price && this.model.offer_price.length
            ? this.model.offer_price.split(",").join("")
            : null;

        if (offer_price && offer_price.length && +offer_price > +price) {
          this.$root.$emit("toast", {
            text: "قیمت با تخفیف نباید بیشتر از قیمت اصلی باشد!",
          });
          return;
        }
        this.loading = true;
        let data = formPostPrepare(this.model);
        data.append("price", price);
        if (!!offer_price) data.append("offer_price", offer_price);
        // data.append("slug", this.model.name.replace(/\s/g, "-"));

        MyAxios.post(`${this.$route.meta.api}/${this.id}/update`, data)
          .then((response) => {
            this.loading = false;

            this.$root.$emit("toast", {
              text: this.$route.meta.title + " با موفقیت ویرایش شد.",
              type: "success",
            });
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;

            if (parseInt(error.response.status) === 500) {
              this.$root.$emit("toast", {
                text:
                  "اسلاگ وارد شده تکراری است یا اشکالی در ثبت اطلاعات به وجود آمده است",
              });
            } else {
              this.$root.$emit("toast", {
                text: "مشکلی پیش آمده است. لطفا دوباره تلاش کنید.",
              });
            }
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
    clear() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
